<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="page-progress-wrap">
        <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
        <div class="progress-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
          <span>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].desc: '' }}</span>
        </div>
        <div class="progress-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
          <span>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].desc: '' }}</span>
        </div>
        <div class="done-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
          <span>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].desc: '' }}</span>
        </div>
      </div>
      <div class="confirmation-box">
        <h3 v-html="!_.isEmpty(dataDetails) ? dataDetails.desc:''"></h3>
        <div class="btn-wrapper justify-between">

          <!-- DIGI -->
          <a v-if="this.operatorId === '1'" class="btn failed" @click="submit('topup-failed')" href="javascript:void(0)" data-option_id="undefined" data-service_id="11"
             data-language="1">{{!_.isEmpty(dataDetails) ? dataDetails.no_btn_text:''}}</a>
          <a v-if="this.operatorId === '1'" class="btn yes" @click="submit('topup-success')" href="javascript:void(0)" data-option_id="undefined" data-service_id="11" data-language="1">{{!_.isEmpty(dataDetails) ? dataDetails.yes_btn_text:''}}</a>

          <!-- UMOBILE -->
<!--          <a v-if="this.operatorId === '2'" class="btn failed" @click="submit('topup-failed')" href="javascript:void(0)" data-option_id="undefined" data-service_id="11"-->
<!--             data-language="1">{{!_.isEmpty(dataDetails) ? dataDetails.no_btn_text:''}}</a>-->
<!--          <form v-if="this.operatorId === '2'" id="umobile_form_topup" method="POST" v-bind:action="this.dataDetails.actionUrl">-->
<!--            <input type="hidden" name="countryCode" :value="this.dataDetails.countryCode">-->
<!--            <input type="hidden" name="siteCode" :value="this.dataDetails.siteCode">-->
<!--            <input type="hidden" name="productName" :value="this.dataDetails.productName">-->
<!--            <input type="hidden" name="price" :value="this.dataDetails.price">-->
<!--            <input type="hidden" name="currencyCode" :value="this.dataDetails.currencyCode">-->
<!--            <input type="hidden" name="callbackUrl" :value="this.dataDetails.callbackUrl">-->
<!--            <input type="hidden" name="requestId" :value="this.dataDetails.requestId">-->
<!--            <input class="btn btn_new" type="submit" @click="dataPush" name="mpaySubmit" :value="this.dataDetails.mpaySubmit">-->
<!--          </form>-->

        </div>
        <br>
        <span style="color: red; font-size: 18px;"></span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'
import { gtmDataPush } from '../../../../public/front/js/gtmHepler'
export default {
  name: 'walletConfirmationPage',
  data () {
    return {
      apiUrl: 'content/',
      updatePromotionUserUrl: '/bd-topup',
      promotionUser: sessionStorage.getItem('promotion_user') ?? '',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      service: '',
      is_loading: true,
      packageAmount: sessionStorage.getItem('packageAmount') ?? '', // package price bd
      packagePrice: sessionStorage.getItem('packagePrice') ?? '', // Rm price
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      walletId: sessionStorage.getItem('walletId') ?? '',
      totalPoint: sessionStorage.getItem('totalPoint') ?? '',
      receiverMsisdn: sessionStorage.getItem('receiverMsisdn'),
      datapackId: sessionStorage.getItem('datapackId'),
      operatorId: localStorage.getItem('operatorId'),
      packageName: sessionStorage.getItem('packageName') ?? '',
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId'),
      sessionId: sessionStorage.getItem('sessionId'),
      priceRmWithCommision: sessionStorage.getItem('priceRmWithCommision') && sessionStorage.getItem('priceRmWithCommision') > 0 ? sessionStorage.getItem('priceRmWithCommision') : sessionStorage.getItem('packageBasePrice')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      if (this.service === 'talktime_topup') {
        this.apiUrl += 'talktime-wallet-confirmation-page'
      } else {
        this.apiUrl += 'wallet-confirmation-page'
      }
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })

      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, 'wallet_confirmation', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, '', {
            params: {
              price: this.packagePrice,
              datapackId: this.datapackId,
              totalPoint: this.totalPoint,
              walletId: this.walletId,
              receiverMsisdn: this.receiverMsisdn,
              packageAmount: this.packageAmount,
              msisdn: this.msisdn,
              languageId: this.languageId,
              operatorId: this.operatorId,
              receiverOperatorId: this.receiverOperatorId,
              priceRmWithCommision: this.priceRmWithCommision
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updatePromotionUser () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.updatePromotionUserUrl, { msisdn: this.msisdn, purpose: 'update', sessionId: this.sessionId })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    submit (data) {
      if (data === 'topup-success') {
        this.dataPush()
      }
      // console.log(data)
      this.$router.push({ name: 'topup-success-failed-page', params: { reason: data } })
    },
    dataPush () {
      if (this.promotionUser === 'yes') {
        this.updatePromotionUser()
      }
      gtmDataPush('begin_checkout', null, this.receiverNumber)
    }
  }
}
</script>

<style scoped>

</style>
